<template>
  <div class="container">
    <!--    <BackButton to="Pension Report" />-->
    <TitleHeader>Pension Report | Traced Pension List</TitleHeader>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Traces Pensions</h5>
        <TracedPensionList
          :status-inactive="true"
          @handle-pension="handleSelect"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import TracedPensionList from "@/components/lists/TracedPensionList";
export default {
  name: "AggregationTracedPensionList",
  components: {
    TracedPensionList
  },
  methods: {
    ...mapMutations(["setLoading"]),
    ...mapActions(["getApplicationsFocus"]),
    handleSelect({ tpuuid }) {
      this.$router.push({
        name: "Pension Form",
        params: {
          uuid: this.$route.params.uuid,
          tpuuid
        }
      });
    }
  },
  async beforeMount() {
    this.setLoading({
      bool: true
    });
    let response = await this.getApplicationsFocus(
      this.$route.params.uuid
    ).catch(err => console.error(err));
    if (!response) return;
    this.setLoading({
      bool: false
    });
  }
};
</script>
<style lang="scss" scoped></style>
